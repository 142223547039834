import { 
	UserState, 
	UserStatus,
	UserInitialized, 
	UserActionTypes, 
	AuthFormValues,
	SocialUser,
	ISocialUser,
	IUserAuthenticate,
	IUserUnauthenticated,
	IUserAuthenticationError,
	IUserAuthenticationSuccess,
	IUserRegister,
	IUserRegistrationError,
	IUserRegistrationSuccess
} from "./user.interfaces";

export const initializedUser = (user: UserState): UserInitialized => ({
  user,
  type: UserActionTypes.INITIALIZED
});

export const userStatus = (user: UserState): UserStatus => ({
	user,
	type: UserActionTypes.USER_STATUS
});

// Authentication Actions
export const oauthlogin = (user:SocialUser): ISocialUser => ({
	user,
	type: UserActionTypes.AUTHENTICATE_GOOGLE
});

// Authentication Actions
export const authenticate = (login:AuthFormValues, history:any): IUserAuthenticate => ({
	login,
	type: UserActionTypes.AUTHENTICATE
});

export const unauthenticate = (state:boolean): IUserUnauthenticated => ({
	state,
	type: UserActionTypes.UNAUTHENTICATED
});

export const authenticationError = (error:string): IUserAuthenticationError => ({
	error,
	type: UserActionTypes.AUTHENTICATION_ERROR
});

export const authenticationSuccess = (success:string): IUserAuthenticationSuccess => ({
	success,
	type: UserActionTypes.AUTHENTICATION_SUCCESS
});

// Registration Actions
export const registerUser = (register:AuthFormValues, history:any): IUserRegister => ({
	register,
	type: UserActionTypes.REGISTER
});

export const registrationError = (error:string): IUserRegistrationError => ({
	error,
	type: UserActionTypes.REGISTRATION_ERROR
});

export const registrationSuccess = (success:string): IUserRegistrationSuccess => ({
	success,
	type: UserActionTypes.REGISTRATION_SUCCESS
});


// export const logout = (logoutObject, history) => dispatch => {
// 	authApi.logout(logoutObject).then(response => {
// 		if(response.error && response.error !== ""){
// 			dispatch({ type: types.LOGOUT_AUTHENTICATION_FAILED, payload: response.error });
// 		}
// 		else{
// 			dispatch({ type: types.LOGOUT_AUTHENTICATION_SUCCESSFUL, payload: loadClientData() });
// 			history.push('/');
// 		}
// 	});
// }

// export const forgotPassword = (email, history) => dispatch => {
// 	authApi.forgotPassword(email).then(response => {
// 		if(response.error && response.error !== ""){
// 			dispatch({ type: types.FORGOT_PASSWORD_FAILED, payload: response.error });
// 		}
// 		else{
// 			let pl = loadClientData();
// 			pl.email = response.email;
// 			pl.success = response.success;
// 			dispatch({ type: types.FORGOT_PASSWORD_SUCCESSFUL, payload: pl });
// 			// history.push('/');
// 		}
// 	});
// }    

// export const resetPassword = (resetPasswordObject, history) => dispatch => {
// 	authApi.resetPassword(resetPasswordObject).then(response => {
// 		if(response.error && response.error !== ""){
// 			dispatch({ type: types.RESET_PASSWORD_FAILED, payload: response.error });
// 		}
// 		else{
// 			let pl = loadClientData();
// 			pl.success = response.success;
// 			dispatch({ type: types.RESET_PASSWORD_SUCCESSFUL, payload: pl });
// 			history.push('/');
// 		}
// 	});
// }


// export const oauthlogin = (reqData, history) => dispatch => {
// 	authApi.oauthlogin(reqData).then(response => {
// 		if(response.error && response.error !== ""){
// 			dispatch({ type: types.OAUTH_AUTHENTICATION_FAILED, payload: response.error });
// 		}
// 		else{
// 			dispatch({ type: types.OAUTH_AUTHENTICATION_SUCCESSFUL, payload: loadClientData() });
// 			history.push('/');
// 		}
// 	});
// }
