import React from "react";
import MUIDataTable from "mui-datatables";
import {MUIDataTableOptions} from "mui-datatables";
// https://github.com/gregnb/mui-datatables
import ReactDOM from "react-dom";
import { CircularProgress, Typography } from '@material-ui/core';

export default class UserList4 extends React.Component {

  state = {
    page: 0,
    count: 1,
    data: [["Loading Data..."]],
    isLoading: false
  };

  componentDidMount() {
    this.getData();
  }

  // get data
  getData = () => {
    this.setState({ isLoading: true });
    this.xhrRequest().then((res:any) => {
      this.setState({ data: res.data, isLoading: false, count: res.total });
    });
  }

  // mock async function
  xhrRequest = () => {

    return new Promise((resolve, reject) => {
      const total = 124;  // mock record count from server
      // mock page data
      const srcData = [
        ["Gabby George", "Business Analyst", "Minneapolis"],
        ["Aiden Lloyd", "Business Consultant", "Dallas"],
        ["Jaden Collins", "Attorney", "Santa Ana"],
        ["Franky Rees", "Business Analyst", "St. Petersburg"],
        ["Aaren Rose", "Business Analyst", "Toledo"]
      ];
      const maxRound =  Math.floor(Math.random() * 2) + 1;
      const data = [...Array(maxRound)].reduce(acc => acc.push(...srcData) && acc, []);
      data.sort((a:any, b:any) => 0.5 - Math.random());

      setTimeout(() => {
        resolve({
          data, total
        });
      }, 2500);

    });

  }

  changePage = (page:string) => {
    this.setState({
      isLoading: true,
    });
    // this.xhrRequest(`/myApiServer?page=${page}`).then((res:any) => {
    //   this.setState({
    //     isLoading: false,
    //     page: page,
    //     data: res.data,
    //     count: res.total,
    //   });
    // });
  };

  render() {

    const columns = ["Name", "Title", "Location"];
    const { data, page, count, isLoading } = this.state;
    const options: MUIDataTableOptions = {
      filter: true,
      filterType: 'dropdown',
      responsive: 'stacked',
      serverSide: true,
      count: count,
      page: page,
      onTableChange: (action:any, tableState:any) => {

        console.log(action, tableState);
        // a developer could react to change on an action basis or
        // examine the state as a whole and do whatever they want

        switch (action) {
          case 'changePage':
            this.changePage(tableState.page);
            break;
        }
      },
      rowsPerPage: 10,
    //   customToolbarSelect: (selectedRows, displayData, setSelectedRows) => (
    //     <CustomToolbarSelect
    //       selectedRows={selectedRows}
    //       displayData={displayData}
    //       setSelectedRows={setSelectedRows}
    //     />
    //   )
    };
    return (
      <div>
        <MUIDataTable title={<Typography 
        // variant="title"
        >
          ACME Employee list
          {isLoading && <CircularProgress size={24} style={{marginLeft: 15, position: 'relative', top: 4}} />}
          </Typography>
          } 
            data={data} 
            columns={columns}
            options={options}
        />
      </div>
    );

  }
}

