import * as React from "react";
import UserList4 from "../components/Authenitcation/UserList4";
import MuiExampleForm from "../components/Authenitcation/UserList3";
import ReactVirtualizedTable from "../components/Authenitcation/UserList2";
import EnhancedTable from "../components/Authenitcation/UserList";
// https://github.com/gregnb/mui-datatables
const Users: React.SFC = () => {
  return (
    <div className="page-container">
      <h1>Users Container</h1>
      <UserList4 /> 
      <MuiExampleForm /> 
      <ReactVirtualizedTable /> 
      <EnhancedTable /> 
    </div>
  );
};

export default Users;