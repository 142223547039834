import * as React from "react";

const Catalog: React.SFC = () => {
  return (
    <div className="page-container">
      <h1>Content Container</h1>
    </div>
  );
};

export default Catalog;