import * as React from "react";
import { connect } from "react-redux";
import {
  Route,
  RouteComponentProps,
  Switch,
  withRouter,
  Redirect
} from "react-router-dom";
import { ApplicationState } from "./redux/Store";
import { UserState, IUserState } from "./redux/users/user.interfaces";

import { CSSTransition, TransitionGroup } from "react-transition-group";

import Header from "./components/Header";
import NotFoundPage from "./components/NotFoundPage";
import Users from "./containers/Users";
import Catalog from "./containers/Catalog";
import DesignStudio from "./containers/DesignStudio";

interface IProps extends RouteComponentProps {
  user: UserState;
}
interface IState {
  user: UserState;
}
class Routes extends React.Component<IProps, IState> {
  public constructor(props: IProps) {
    super(props);
    // console.log("props",props);
    this.state = {
      user: IUserState
    };
  }
  public componentDidMount() {
    this.setState({ user:  this.props.user});
  }
  public componentWillReceiveProps(nextProps:any) {
      if(nextProps.user !== this.state.user){
          this.setState({ user:  nextProps.user});
      }
  }
  public render() {
    return (
      <div>
        <Header user={this.state.user}/>
        <TransitionGroup>
          <CSSTransition
            key={this.props.location.key}
            timeout={500}
            classNames="animate"
          >
            <Switch>
              <Route exact={true} path="/" component={Catalog} />
              <Route exact={true} path="/users" component={Users} />
              <Route exact={true} path="/catalog" component={Catalog} />
              <Route exact={true} path="/design-studio" component={DesignStudio} />
              <Route path="/admin">
              {this.state.user.loggedIn ? (
                  <DesignStudio />
              ) : (
                <Redirect to="/" />
              )}
            </Route>
              <Route component={NotFoundPage} />
            </Switch>
          </CSSTransition>
        </TransitionGroup>
      </div>
    );
  }
}

const mapStateToProps = (store: ApplicationState) => {
  return {
    user: store.user
  };
};

export default connect(mapStateToProps)(withRouter(Routes));
