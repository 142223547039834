import { Reducer } from "redux";
import { UserState, IUserState, UserActions, UserActionTypes } from "./user.interfaces";

const initialUserState: UserState = IUserState;

export const userReducer: Reducer<UserState, UserActions> = (
  state = initialUserState,
  action
) => {
  switch (action.type) {
    case UserActionTypes.INITIALIZE: {
      return {
        ...state,
        loggedIn: action.user.loggedIn,
        email: action.user.email,
      };
    }
    case UserActionTypes.INITIALIZED: {
      return {
        ...state,
        loggedIn: action.user.loggedIn,
        email: action.user.email,
      };
    }
    case UserActionTypes.USER_STATUS: {
      return {
        ...state,
        loggedIn: action.user.loggedIn,
        email: action.user.email,
      };
    }
    case UserActionTypes.AUTHENTICATE: {
      return state;
    }
    case UserActionTypes.UNAUTHENTICATED: {
      return {
        ...state,
        loggedIn: false,
        email: "",
      };
    }
    case UserActionTypes.REGISTRATION_ERROR:
    case UserActionTypes.AUTHENTICATION_ERROR: {
      return {
        ...state,
        loginForm : {
          ...state.loginForm,
          errorMessage: action.error
        }
      };
    }
    case UserActionTypes.AUTHENTICATE_GOOGLE: {
      return {
        ...state,
        email: action.user.email,
        loggedIn: true,
      };
    }
    case UserActionTypes.AUTHENTICATION_SUCCESS: {
      return {
        ...state,
        email: action.success,
        loggedIn: true,
      };
    }
    case UserActionTypes.REGISTRATION_SUCCESS: {
      return {
        ...state,
        loginForm : {
          ...state.loginForm,
          successMessage: action.success
        }
      };
    }
  }
  return state || initialUserState;
};
