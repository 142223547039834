import React from "react";

import Grid from '@material-ui/core/Grid';

class DesignStudio extends React.Component {

  // app: Pixi.Application;
  // gameCanvas: HTMLDivElement;

  render() {
    return (
      <div className="App">
        <Grid
          container
          direction="column"
          justify="flex-start"
          alignItems="stretch"
        >
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="center"
          >
            <div></div>
            <div className="design-studio">
              <p>Design studio</p>
              <div id="design-studio-wrapper"></div>
            </div>
            <div></div>
          </Grid>
        </Grid>
      </div>
    );
  }
} 

export default DesignStudio;
