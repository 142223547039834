import React from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { ApplicationState } from "../redux/Store";
import { RouteComponentProps, withRouter } from "react-router-dom";
import Button from '@material-ui/core/Button';
import { Formik, Field } from "formik";
import * as Yup from 'yup';
import LoginWrapper from "../styles/LoginWrapper";
import FacebookLoginButton from "./Authenitcation/FacebookLoginButton";
import GoogleLoginButton from "./Authenitcation/GoogleLoginButton";
import ForgotPassword from "./Authenitcation/ForgotPassword";

import { 
    oauthlogin,
    authenticate,
    authenticationError,
    authenticationSuccess,
    registerUser,
    registrationError,
    registrationSuccess
} from "../redux/users/user.actions";
import { AuthApi } from "../redux/users/user.api";
import { UserState, AuthFormValues, IAuthFormValues } from "../redux/users/user.interfaces";



interface IProps extends RouteComponentProps {
    user: UserState;
    oauthlogin: typeof oauthlogin;
    authenticate: typeof authenticate;
    authenticationError: typeof authenticationError;
    authenticationSuccess: typeof authenticationSuccess;
    registerUser: typeof registerUser;
    registrationError: typeof registrationError;
    registrationSuccess: typeof registrationSuccess;
  }

interface IState {
    activeForm: string;
    user: UserState;
  }
class LoginForm extends React.Component<IProps, IState> {
    
    public constructor(props: IProps) {
      super(props);
      this.state = {
        activeForm: 'login',
        user: this.props.user
      };
      this.toggleLoginRegistration = this.toggleLoginRegistration.bind(this);
      this.handlingLogin = this.handlingLogin.bind(this);
      this.handlingRegistration = this.handlingRegistration.bind(this);
    }
    
    componentWillReceiveProps(nextProps:any) {
        if(nextProps.user !== this.state.user){
            this.setState({ user:  nextProps.user});
        }
    }

    toggleLoginRegistration(activeForm: string){
        this.setState({activeForm:activeForm});
    }

    handlingLogin(values: AuthFormValues){
        this.props.authenticate(values, this.props.history);
        AuthApi.authenticate(values)
            .then(response => {
                console.log("response", response);
                if(response.data.error){
                    this.props.authenticationError(response.data.error);
                } else {
                    this.props.authenticationSuccess(response.data.email);
                }
            })
            .catch(ex => {
                console.log("ex", ex);
            });
    }

    handlingRegistration(values: AuthFormValues){
        this.props.registerUser(values, this.props.history);
        AuthApi.registerUser(values)
            .then(response => {
                console.log("response", response);
                if(response.data.error){
                    this.props.registrationError(response.data.error);
                } else {
                    this.props.registrationSuccess(response.data.success);
                }
            })
            .catch(ex => {
                console.log("ex", ex);
            });
    }

    handlingChange(){
        this.props.registrationError("");
        this.props.registrationSuccess("");
    }

    public render() {
        return (
        <LoginWrapper>
            {
                this.state.activeForm === 'login'
                ?
                    <div>
                        <Formik initialValues={IAuthFormValues}
                            onSubmit={this.handlingLogin}
                            validationSchema={Yup.object().shape({
                                email: Yup.string()
                                .email("Email not valid")
                                .required("Email is required"),
                                password: Yup.string()
                                    .min(5, 'Password too short!')
                                    .required("Password is required"),
                            })}
                            render={({ handleSubmit, errors, touched, setFieldValue, setErrors, setSubmitting }) => (
                                <form onSubmit={handleSubmit} >
                                    <label htmlFor="email">
                                        <div>Email</div>
                                        <Field 
                                            type="email" 
                                            name="email"
                                            onChange={(e: React.ChangeEvent<any>) => {
                                                this.handlingChange();
                                                setFieldValue('email', e.target.value);
                                            }}/>
                                        {
                                            touched.email && errors.email
                                            ? <div>{errors.email}</div>
                                            : null
                                        }
                                    </label>
                                    <label htmlFor="password">
                                        <div>Password</div>
                                        <Field
                                            type="password"
                                            name="password"
                                            onChange={(e: React.ChangeEvent<any>) => {
                                                this.handlingChange();
                                                setFieldValue('password', e.target.value);
                                            }}/>
                                        {
                                            touched.password && errors.password
                                            ? <div>{errors.password}</div>
                                            : null
                                        }
                                    </label>
                                    <br/>
                                    {
                                        this.state.user.loginForm.errorMessage
                                        ? <p>Error: {this.state.user.loginForm.errorMessage}</p>
                                        : null
                                    }
                                    <Button
                                        aria-controls="customized-menu"
                                        aria-haspopup="true"
                                        variant="contained"
                                        color="primary"
                                        type="submit"
                                        >
                                        Login
                                    </Button>
                                </form>
                            )}>
                        </Formik>
                        <FacebookLoginButton></FacebookLoginButton>
                        <GoogleLoginButton></GoogleLoginButton>
                        <Button
                            aria-controls="customized-menu"
                            aria-haspopup="true"
                            variant="contained"
                            color="primary"
                            onClick={this.toggleLoginRegistration.bind(this, 'forgotPassword')}
                            >
                            Forgot Password
                        </Button>
                        <div>
                            <Button
                                aria-controls="customized-menu"
                                aria-haspopup="true"
                                variant="contained"
                                color="primary"
                                onClick={this.toggleLoginRegistration.bind(this, 'register')}
                                >
                                Create Account
                            </Button>
                        </div>
                    </div>
                :
                    null
            }
            {
                this.state.activeForm === 'register'
                ?
                    <div>
                        <Formik
                            initialValues={IAuthFormValues}
                            onSubmit={this.handlingRegistration}
                            validationSchema={Yup.object().shape({
                                email: Yup.string()
                                .email("Email not valid")
                                .required("Email is required"),
                                password: Yup.string()
                                    .min(5, 'Password too short!')
                                    .required("Password is required"),
                                cpassword: Yup.string()
                                    .oneOf([Yup.ref('password')],'Passwords must match!!!')
                                    .required("Confirm password is required"),
                            })}
                            render={({ handleSubmit, errors, touched, setFieldValue }) => (
                                <form onSubmit={handleSubmit}>
                                    <label htmlFor="email">
                                        <div>Email</div>
                                        <Field
                                            type="email"
                                            name="email"
                                            onChange={(e: React.ChangeEvent<any>) => {
                                                this.handlingChange();
                                                setFieldValue('email', e.target.value);
                                            }}/>
                                        {
                                            touched.email && errors.email
                                            ? <div>{errors.email}</div>
                                            : null
                                        }
                                    </label>
                                    <label htmlFor="password">
                                        <div>Password</div>
                                        <Field
                                            type="password"
                                            name="password"
                                            onChange={(e: React.ChangeEvent<any>) => {
                                                this.handlingChange();
                                                setFieldValue('password', e.target.value);
                                            }}/>
                                        {
                                            touched.password && errors.password
                                            ? <div>{errors.password}</div>
                                            : null
                                        }
                                    </label>
                                    <label htmlFor="cpassword">
                                        <div>Confirm Password</div>
                                        <Field
                                            type="password"
                                            name="cpassword"
                                            onChange={(e: React.ChangeEvent<any>) => {
                                                this.handlingChange();
                                                setFieldValue('cpassword', e.target.value);
                                            }}/>
                                        {
                                            touched.cpassword && errors.cpassword
                                            ? <div>{errors.cpassword}</div>
                                            : null
                                        }
                                    </label>
                                    <br/>
                                    {
                                        this.state.user.loginForm.errorMessage
                                        ? <p>Error: {this.state.user.loginForm.errorMessage}</p>
                                        : null
                                    }
                                    {
                                        this.state.user.loginForm.successMessage
                                        ? <p>Success: {this.state.user.loginForm.successMessage}</p>
                                        : null
                                    }
                                    <Button
                                        aria-controls="customized-menu"
                                        aria-haspopup="true"
                                        variant="contained"
                                        color="primary"
                                        type="submit"
                                        >
                                        Sign Up
                                    </Button>
                                </form>
                            )}>
                        </Formik>
                        <div>
                            <Button
                                aria-controls="customized-menu"
                                aria-haspopup="true"
                                variant="contained"
                                color="primary"
                                onClick={this.toggleLoginRegistration.bind(this, 'login')}
                                >
                                Back to login
                            </Button>
                        </div>
                    </div>
                :
                    null
            }
            {
                this.state.activeForm === 'forgotPassword'
                ?
                    <div>
                        <ForgotPassword/>
                        <Button
                            aria-controls="customized-menu"
                            aria-haspopup="true"
                            variant="contained"
                            color="primary"
                            onClick={this.toggleLoginRegistration.bind(this, 'login')}
                            >
                            Cancel
                        </Button>
                    </div>
                :
                    null
            }
        </LoginWrapper>
        )
    }
}

const mapStateToProps = (store: ApplicationState) => {
    // console.log("store",store);
    return {
      user: store.user
    };
  };

  
function mapDispatchToProps(dispatch: Dispatch){
	return {
		oauthlogin: bindActionCreators(oauthlogin, dispatch),
		authenticate: bindActionCreators(authenticate, dispatch),
		authenticationError: bindActionCreators(authenticationError, dispatch),
		authenticationSuccess: bindActionCreators(authenticationSuccess, dispatch),
		registerUser: bindActionCreators(registerUser, dispatch),
		registrationError: bindActionCreators(registrationError, dispatch),
		registrationSuccess: bindActionCreators(registrationSuccess, dispatch)
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(LoginForm));
