
export enum UserActionTypes {
    INITIALIZE = "USER/INITIALIZE",
    USER_STATUS = "USER/USER_STATUS",
    INITIALIZED = "USER/INITIALIZED",
    AUTHENTICATE_GOOGLE = "USER/AUTHENTICATE_GOOGLE",
    AUTHENTICATE = "USER/AUTHENTICATE",
    UNAUTHENTICATED = "USER/UNAUTHENTICATED",
    AUTHENTICATION_ERROR = "USER/AUTHENTICATION_ERROR",
    AUTHENTICATION_SUCCESS = "USER/AUTHENTICATION_SUCCESS",
    REGISTER = "USER/REGISTER",
    REGISTRATION_ERROR = "USER/REGISTRATION_ERROR",
    REGISTRATION_SUCCESS = "USER/REGISTRATION_SUCCESS",
}

export interface UserState {
    loggedIn: boolean,
    email: string,
    loginForm: LoginFormState
}
export interface SocialUser{
    accessToken: string;
    email: string;
    source: string;
}


export interface AuthFormValues {
    email: string;
    password: string;
    cpassword: string;
}

export const IAuthFormValues: AuthFormValues = {
    email: "",
    password: "",
    cpassword: "",
};

export interface ForgotFormValues {
    emailF: string;
}

export const IForgotFormValues: ForgotFormValues = {
    emailF: ""
};

export interface ForgotFormConfirmValues {
    accessCode: string;
    passwordF: string;
    cpasswordF: string;
}

export const IForgotFormConfirmValues: ForgotFormConfirmValues = {
    accessCode: "",
    passwordF: "",
    cpasswordF: "",
};

export interface LoginFormState {
    emailField: string,
    passwordFields: string,
    errorMessage: string,
    successMessage: string
}

export const IUserState = {
    loggedIn: false,
    email: '',
    loginForm : {
        emailField: '',
        passwordFields: '',
        errorMessage: '',
        successMessage: ''
    }
};

export interface UserInitialize {
    type: UserActionTypes.INITIALIZE;
    user: UserState;
}

export interface UserStatus {
    type: UserActionTypes.USER_STATUS;
    user: UserState;
}

export interface UserInitialized {
    type: UserActionTypes.INITIALIZED;
    user: UserState;
}

export interface IUserAuthenticate {
	type: UserActionTypes.AUTHENTICATE;
	login: any;
}
export interface ISocialUser {
	type: UserActionTypes.AUTHENTICATE_GOOGLE;
	user: SocialUser;
}
export interface IUserAuthenticate {
	type: UserActionTypes.AUTHENTICATE;
	login: any;
}
export interface IUserUnauthenticated {
	type: UserActionTypes.UNAUTHENTICATED;
	state: boolean;
}

export interface IUserAuthenticationError {
	type: UserActionTypes.AUTHENTICATION_ERROR;
	error: string;
}

export interface IUserAuthenticationSuccess {
	type: UserActionTypes.AUTHENTICATION_SUCCESS;
	success: string;
}

export interface IUserRegister {
	type: UserActionTypes.REGISTER;
	register: any;
}
export interface IUserRegistrationError {
	type: UserActionTypes.REGISTRATION_ERROR;
	error: string;
}

export interface IUserRegistrationSuccess {
	type: UserActionTypes.REGISTRATION_SUCCESS;
	success: string;
}

export type UserActions =   UserInitialize |
                            UserInitialized |
                            UserStatus |
                            IUserUnauthenticated |
                            IUserAuthenticate |
                            ISocialUser |
                            IUserAuthenticationError |
                            IUserAuthenticationSuccess |
                            IUserRegister |
                            IUserRegistrationError |
                            IUserRegistrationSuccess;

