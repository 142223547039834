import { applyMiddleware, combineReducers, createStore } from "redux";
import thunk from "redux-thunk";
import { userReducer } from "./users/user.reducer";
import { UserState } from "./users/user.interfaces";

export interface ApplicationState {
  user: UserState;
}

const rootReducer = combineReducers<ApplicationState>({
  user: userReducer
});

export const AppStore = createStore(rootReducer, undefined, applyMiddleware(thunk));
