import React from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import GoogleLogin from 'react-google-login';

import { oauthlogin } from "../../redux/users/user.actions";
import { AuthApi } from "../../redux/users/user.api";
import { SocialUser } from "../../redux/users/user.interfaces";

interface IProps extends RouteComponentProps {
    oauthlogin: typeof oauthlogin;
}

class GoogleLoginButton extends React.Component<IProps, {}> {

    public constructor(props: IProps) {
      super(props);
      this.state = {
        showLogin: true
      };
      this.responseGoogle = this.responseGoogle.bind(this);
      this.onGoogleLogin = this.onGoogleLogin.bind(this);
    }

    onGoogleLogin(loginStatus:boolean, googleUser?:SocialUser) {
        if(loginStatus === true){
            if(googleUser && googleUser.email){
            console.log("fixme dispatch oAuthLogin with access token");
            AuthApi.oauthlogin(googleUser)
                .then(response => {
                    console.log("response", response);
                    if(response.data.email && response.data.email === googleUser.email){
                        this.props.oauthlogin(googleUser);
                    } else {
                        console.log("we got problems");
                    }
                })
                .catch(ex => {
                    console.log("ex", ex);
                });
            } 
        }
        else {
            console.log("login failed dispatch error message");
        }
    }

    responseGoogle(response:any){
        console.log(response);
        if(response.isSignedIn() === true){
            let googleObj:SocialUser = {
                email : response.getBasicProfile().getEmail(),
                accessToken: response.tokenObj.access_token,
                source: "google"
            };
            this.onGoogleLogin(true, googleObj);
        } else{
            this.onGoogleLogin(false);
        }
      }
    render() {
        return (
            <GoogleLogin
                clientId="518971619716-bkf6sh3ef7ksj86ntq387g7sjavoip8o.apps.googleusercontent.com" //CLIENTID NOT CREATED YET
                buttonText="LOGIN WITH GOOGLE"
                onSuccess={this.responseGoogle}
                onFailure={this.responseGoogle}
            />
        );
    }

}

const mapDispatchToProps = (dispatch: Dispatch) => {
	return {
		oauthlogin: bindActionCreators(oauthlogin, dispatch)
	};
}

export default connect(null, mapDispatchToProps)(withRouter(GoogleLoginButton));