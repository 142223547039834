import React from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { ApplicationState } from "../../redux/Store";
import { RouteComponentProps, withRouter } from "react-router-dom";
import Button from '@material-ui/core/Button';
import { Formik, Field } from "formik";
import * as Yup from 'yup';

import { 
    oauthlogin
} from "../../redux/users/user.actions";
import { AuthApi } from "../../redux/users/user.api";
import { 
    ForgotFormValues,
    IForgotFormValues,
    ForgotFormConfirmValues,
    IForgotFormConfirmValues
 } from "../../redux/users/user.interfaces";

interface IProps extends RouteComponentProps {
    oauthlogin: typeof oauthlogin;
}

interface IState {
    showForgotPasswordConfirm: boolean;
    successMessage: string;
    errorMessage: string;
    email: string;
}

class ForgotPassword extends React.Component<IProps, IState> {

    public constructor(props: IProps) {
      super(props);
      this.state = {
        showForgotPasswordConfirm: false,
        successMessage: "",
        errorMessage: "",
        email: ""
      };
    this.forgotPasswordSubmit = this.forgotPasswordSubmit.bind(this); 
    this.resetPasswordSubmit = this.resetPasswordSubmit.bind(this);
    this.updateLoginState = this.updateLoginState.bind(this);
    this.updateAccessFormState = this.updateAccessFormState.bind(this);
    }

    forgotPasswordSubmit(values: ForgotFormValues) {
        //   this.props.actions.forgotPassword(forgotPasswordObject, this.props.history); 
        AuthApi.forgotPassword(values)
            .then(response => {
                console.log("response", response);
                if(response.data.error){
                    this.setState({ showForgotPasswordConfirm: false});
                    this.setState({ errorMessage: response.data.error});
                    this.setState({ successMessage: ""});
                } else {
                    this.setState({ showForgotPasswordConfirm: true});
                    this.setState({ errorMessage: ""});
                    this.setState({ successMessage: response.data.success});
                    this.setState({ email: response.data.email});
                }
            })
            .catch(ex => {
                console.log("ex", ex);
            });  
    }
    
    
      resetPasswordSubmit(values:ForgotFormConfirmValues) {
        // event.preventDefault();
        const email = this.state.email;
        AuthApi.resetPassword(values, email).then(response => {
            console.log("response", response);
            if(response.data.error){
                this.setState({ showForgotPasswordConfirm: false});
                this.setState({ errorMessage: response.data.error});
                this.setState({ successMessage: ""});
            } else {
                this.setState({ showForgotPasswordConfirm: true});
                this.setState({ errorMessage: ""});
                this.setState({ successMessage: response.data.success});
            }
        })
        .catch(ex => {
            console.log("ex", ex);
        });  
      }
    
    
      updateAccessFormState(event:any) {
        // this.setState({ errors: {} });
        const field = event.target.name;
        // let accessForm = this.state.accessForm;
        // accessForm[field] = event.target.value;
        // return this.setState({ accessForm: accessForm });
      }
    
    
      updateLoginState(event:any) {
        // this.setState({ errors: {} });
        const field = event.target.name;
        // let login = this.state.login;
        // login[field] = event.target.value;
        // return this.setState({ login: login });
      }

    handlingChange(){
        // this.props.registrationError("");
        // this.props.registrationSuccess("");
    }

    render() {
        return (
            <div>
            {
                this.state.successMessage !== ""
                ?
                    <p>{this.state.successMessage}</p>
                :
                    null
            }
            {
                this.state.errorMessage !== ""
                ?
                    <p>{this.state.errorMessage}</p>
                :
                    null
            }
            {
                this.state.showForgotPasswordConfirm === true
                ? 
                    <div>
                        <Formik
                            initialValues={IForgotFormConfirmValues}
                            onSubmit={this.resetPasswordSubmit}
                            validationSchema={Yup.object().shape({
                                accessCode: Yup.string()
                                    .required("Access code is required"),
                                passwordF: Yup.string()
                                    .min(5, 'Password too short!')
                                    .required("Password is required"),
                                cpasswordF: Yup.string()
                                    .oneOf([Yup.ref('passwordF')],'Passwords must match!!!')
                                    .required("Confirm password is required"),
                            })}
                            render={({ handleSubmit, errors, touched, setFieldValue }) => (
                                <form onSubmit={handleSubmit}>
                                    <label htmlFor="accessCode">
                                        <div>Access Code</div>
                                        <Field
                                            type="text"
                                            name="accessCode"
                                            onChange={(e: React.ChangeEvent<any>) => {
                                                this.handlingChange();
                                                setFieldValue('accessCode', e.target.value);
                                            }}/>
                                        {
                                            touched.accessCode && errors.accessCode
                                            ? <div>{errors.accessCode}</div>
                                            : null
                                        }
                                    </label>
                                    <label htmlFor="passwordF">
                                        <div>New password</div>
                                        <Field
                                            type="passwordF"
                                            name="passwordF"
                                            onChange={(e: React.ChangeEvent<any>) => {
                                                this.handlingChange();
                                                setFieldValue('passwordF', e.target.value);
                                            }}/>
                                        {
                                            touched.passwordF && errors.passwordF
                                            ? <div>{errors.passwordF}</div>
                                            : null
                                        }
                                    </label>                                    
                                    <label htmlFor="cpasswordF">
                                        <div>Confirm password</div>
                                        <Field
                                            type="cpasswordF"
                                            name="cpasswordF"
                                            onChange={(e: React.ChangeEvent<any>) => {
                                                this.handlingChange();
                                                setFieldValue('cpasswordF', e.target.value);
                                            }}/>
                                        {
                                            touched.cpasswordF && errors.cpasswordF
                                            ? <div>{errors.cpasswordF}</div>
                                            : null
                                        }
                                    </label>
                                    {/* <br/>
                                    {
                                        this.state.user.loginForm.errorMessage
                                        ? <p>Error: {this.state.user.loginForm.errorMessage}</p>
                                        : null
                                    }
                                    {
                                        this.state.user.loginForm.successMessage
                                        ? <p>Success: {this.state.user.loginForm.successMessage}</p>
                                        : null
                                    } */}
                                    <Button
                                        aria-controls="customized-menu"
                                        aria-haspopup="true"
                                        variant="contained"
                                        color="primary"
                                        type="submit"
                                        >
                                        Update Password
                                    </Button>
                                </form>
                            )}>
                        </Formik>
                    </div>
                :
                    null
            }
            {
                this.state.showForgotPasswordConfirm === false
                ?
                    <Formik
                        initialValues={IForgotFormValues}
                        onSubmit={this.forgotPasswordSubmit}
                        validationSchema={Yup.object().shape({
                            emailF: Yup.string()
                                .email("Email not valid")
                                .required("Email is required"),
                        })}
                        render={({ handleSubmit, errors, touched, setFieldValue }) => (
                            <form onSubmit={handleSubmit}>
                                <label htmlFor="emailF">
                                    <div>Email</div>
                                    <Field
                                        type="email"
                                        name="emailF"
                                        onChange={(e: React.ChangeEvent<any>) => {
                                            this.handlingChange();
                                            setFieldValue('emailF', e.target.value);
                                        }}/>
                                    {
                                        touched.emailF && errors.emailF
                                        ? <div>{errors.emailF}</div>
                                        : null
                                    }
                                </label>
                                
                                {/* <br/>
                                {
                                    this.state.user.loginForm.errorMessage
                                    ? <p>Error: {this.state.user.loginForm.errorMessage}</p>
                                    : null
                                }
                                {
                                    this.state.user.loginForm.successMessage
                                    ? <p>Success: {this.state.user.loginForm.successMessage}</p>
                                    : null
                                } */}
                                <Button
                                    aria-controls="customized-menu"
                                    aria-haspopup="true"
                                    variant="contained"
                                    color="primary"
                                    type="submit"
                                    >
                                    Forgot Password
                                </Button>
                            </form>
                        )}>
                    </Formik>
                :
                    null
            }
          </div>
        );
    }

}

const mapDispatchToProps = (dispatch: Dispatch) => {
	return {
		oauthlogin: bindActionCreators(oauthlogin, dispatch)
	};
}

export default connect(null, mapDispatchToProps)(withRouter(ForgotPassword));