import * as React from "react";
import axios, { CancelTokenSource } from "axios";
import { AuthFormValues, SocialUser, ForgotFormValues, ForgotFormConfirmValues } from "./user.interfaces";

import config from '../../config';

const _baseUrl: string = config.baseUrl;
// const host = "http://localhost:8443/";
const cancelToken = axios.CancelToken;

export class AuthApi {
    cancelTokenSource:CancelTokenSource;
    constructor(){
        const [cancelTokenSource]: [
            CancelTokenSource,
            (cancelSourceToken: CancelTokenSource) => void
          ] = React.useState(cancelToken.source());
          this.cancelTokenSource = cancelTokenSource;
    }
    static authenticate(login:AuthFormValues) {
        return axios
            .post<any>(_baseUrl + "login", {
                //   cancelToken: this.cancelTokenSource.token,
                headers: {
                    "Content-Type": "application/json"
                },
                    Email: login.email,
                    Password: login.password,
                timeout: 5000
            })
    }

    static registerUser(register:AuthFormValues) {
        return axios
            .post<any>(_baseUrl + "register", {
                //   cancelToken: this.cancelTokenSource.token,
                headers: {
                    "Content-Type": "application/json"
                },
                    Email: register.email,
                    Password: register.password,
                timeout: 5000
            })
    }

    static unauthenticate(logout:boolean) {
        return axios
            .post<any>(_baseUrl + "logout", {
                headers: {
                    "Content-Type": "application/json"
                },
                timeout: 5000
            })
    }

    static forgotPassword(values: ForgotFormValues) {
        const url = _baseUrl + "forgot_passworded"
        return axios
            .post<any>(url, {
                //   cancelToken: this.cancelTokenSource.token,
                headers: {
                    "Content-Type": "application/json"
                },
                Email:values.emailF,
                timeout: 5000
            })
    }
    // static resetPassword(resetPasswordObject) {
    //     return makeAuthRequest("reset_password", "post", resetPasswordObject);
    // }
    static resetPassword(values: ForgotFormConfirmValues, email: string) {
        const url = _baseUrl + "reset_password"
        return axios
            .post<any>(url, {
                //   cancelToken: this.cancelTokenSource.token,
                headers: {
                    "Content-Type": "application/json"
                },
                Email: email,
                AccessCode: values.accessCode,
                Password: values.passwordF,
                timeout: 5000
            })
    }

    static oauthlogin(user:SocialUser) {
        const url = _baseUrl + "oauthLogin/?accessToken="+user.accessToken + "&source="+user.source
        return axios
            .get<any>(url, {
                //   cancelToken: this.cancelTokenSource.token,
                headers: {
                    "Content-Type": "application/json"
                },
                timeout: 5000
            })
        // return makeAuthRequest(url, "get");
    }

    // static validateToken() {
    //     return makeAuthRequest("session", "post", { token: ""});
    // }



    // static forgotPassword(email) {
    //     return makeAuthRequest("forgot_passworded", "post", email);
    // }
    
    // static resetPassword(resetPasswordObject) {
    //     return makeAuthRequest("reset_password", "post", resetPasswordObject);
    // }
    
    // static oauthlogin(reqData) {
    //     const url = "fblogin/?accessToken="+reqData.accessToken + "&source="+reqData.source
    //     return makeAuthRequest(url, "get");
    // }
}

export default AuthApi;