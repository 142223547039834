import * as React from 'react';
import * as ReactDOM from 'react-dom';
import {
    BrowserRouter as Router,
    Route
} from "react-router-dom";
import { Provider } from "react-redux";
import { Store } from "redux";
import './index.css';
import Routes from "./Routes";
import { AppStore } from "./redux/Store";
import { ApplicationState } from "./redux/Store";
import { initializeSession, authResponse } from './api/authentication.service';
import { IUserState } from "./redux/users/user.interfaces";
import decode from 'jwt-decode';
import { userStatus } from "./redux/users/user.actions";

interface IProps {
    store: Store<ApplicationState>;
}
const initApp = () => {
    const Root: React.FC<IProps> = props => {
        return (
            <Provider store={props.store}>
                <Router>
                    <Route component={Routes} />
                </Router>
            </Provider>
        );
    };
    ReactDOM.render(<Root store={AppStore} />, document.getElementById("root") as HTMLElement);
}
// Set app session
initializeSession()
    .then(response => {
        // Set authenticated
        const sessData:authResponse = decode(response.headers["access-token"]);
        let user = IUserState;
        if(sessData.sid !== ""){
            const sessionDataEncrypted = sessionStorage.getItem('clientData');
            if(sessionDataEncrypted){
                const sessionData = JSON.parse(sessionDataEncrypted);
                if(sessionData.email){
                    user.email = sessionData.email;
                } else {
                    user.email = "Some Brotha";
                }
            } 
            user.loggedIn = true;
        } else{
            user.loggedIn = false;
            user.email = "";
        }
        AppStore.dispatch(userStatus(user));
        initApp();  
  })
  .catch(ex => {
      console.log("ex", ex);
      initApp();
  });