import * as React from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { ApplicationState } from "../redux/Store";
import { unauthenticate } from "../redux/users/user.actions";
import { UserState } from "../redux/users/user.interfaces";
import { AuthApi } from "../redux/users/user.api";

import Button from '@material-ui/core/Button';
import styles from './Login.module.css';

import LoginForm from './LoginForm';



interface IProps extends RouteComponentProps {
    user: UserState;
    unauthenticate: typeof unauthenticate;
  }

interface IState {
    showLogin: boolean;
    buttonText: string;
    user: UserState;
  }
class Login extends React.Component<IProps, IState> {
    public constructor(props: IProps) {
      super(props);
      this.state = {
        showLogin: false,
        buttonText: 'Sign In',
        user: this.props.user
      };
      this.handleLogout = this.handleLogout.bind(this);
    }

    public componentWillReceiveProps(nextProps:any) {
        if(nextProps.user !== this.state.user){
            this.setState({ user:  nextProps.user});
            if(nextProps.user.loggedIn === true && this.state.user.loggedIn === false){
                this.setState({showLogin:false});
                this.toggleLoginText();
            }
        }
    }

    toggleLoginText = () => {
        if(this.state.showLogin === false){
            this.setState({buttonText: 'X'});
        } else {
            this.setState({buttonText:'Sign In'});
        }
    }

    handleClick = (event: React.MouseEvent<HTMLElement>) => {
        this.setState({showLogin:!this.state.showLogin});
        this.toggleLoginText();
    }
  
    handleLogout() {
        // maybe add logging out in progress logic?
        AuthApi.unauthenticate(true)
            .then(response => {
                console.log("response", response);
                if(response.data.error){
                    console.log("error loggin out...");
                    // this.props.authenticationError(response.data.error);
                } else {
                    this.props.unauthenticate(true);
                }
            })
            .catch(ex => {
                console.log("ex", ex);
            });
    }


    public render() {
        return <div>
        {(this.state.user.loggedIn === true) ?
            <div>
                    <p>Hi, {this.state.user.email}</p>
                    <Button
                        aria-controls="customized-menu"
                        aria-haspopup="true"
                        variant="contained"
                        color="primary"
                        onClick={this.handleLogout}
                        >
                        Logout
                    </Button>
                    </div>
        :
            <div>
                        <Button
                        aria-controls="customized-menu"
                        aria-haspopup="true"
                        variant="contained"
                        color="primary"
                        onClick={this.handleClick}
                        >
                        {this.state.buttonText}
                        </Button>
                        <div className={styles.loginFormWrapper}>
                            <div className={this.state.showLogin ? styles.divStyleOpen : styles.divStyleClosed}>
                                <LoginForm />
                            </div>
                        </div>
                    </div>
        }
        </div>
    }
};

const mapStateToProps = (store: ApplicationState) => {
    // console.log("store",store);
    return {
      user: store.user
    };
};

function mapDispatchToProps(dispatch: Dispatch){
	return {
		unauthenticate: bindActionCreators(unauthenticate, dispatch)
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Login));

