import axios from 'axios';
import decode from 'jwt-decode';
import config from '../config';

const _baseUrl: string = config.baseUrl;
// const _baseUrl: string = 'http://localhost:8443/';
// const _baseUrl: string = 'https://0fk4p6qhof.execute-api.us-west-2.amazonaws.com/authorization/';
// const _baseUrl: string = 'https://0fk4p6qhof.execute-api.us-west-2.amazonaws.com/auth_no_update/';
// const _baseUrl: string = 'https://zuaxvp142b.execute-api.us-west-2.amazonaws.com/authorization/';
// const _baseUrl: string = 'https://zuaxvp142b.execute-api.us-west-2.amazonaws.com/auth12/';
// const _baseUrl: string = 'https://zuaxvp142b.execute-api.us-west-2.amazonaws.com/auth123/';
const enpoints = {
    session: 'session'
};

interface SessionData {
    token: string;
    email: string;
}
const ISessionData: SessionData = {
    token: '',
    email: ''
}
export interface authResponse {
    email: string;
    sid: string;
    exp: Date;
}

const _getSessionData = () : SessionData => {
    const sessionDataEncrypted = sessionStorage.getItem('clientData');
    if(sessionDataEncrypted){
        const sessionData = JSON.parse(sessionDataEncrypted);
        if(sessionData && sessionData.token){
            return sessionData;
        }
    } else {
        console.log("no session data!!!");
    }
    return ISessionData;
}

const _setSessionData = (sessionDataHash:string) => {
    const sd:authResponse = decode(sessionDataHash);
    const sessionDataEncrypted = sessionStorage.getItem('clientData');
    if(sessionDataEncrypted){
        const sessionData = JSON.parse(sessionDataEncrypted);
        sessionData.token = sessionDataHash;
        if(sd.email){
            sessionData.email = sd.email;
        }
        sessionStorage.setItem('clientData', JSON.stringify(sessionData));
    } else {
        ISessionData.token = sessionDataHash;
        if(sd.email){
            ISessionData.email = sd.email;
        }
        sessionStorage.setItem('clientData', JSON.stringify(ISessionData));
    }
}

// Add a request interceptor
axios.interceptors.request.use(function (config) {
    // Do something before request is sent
    const sessionData = _getSessionData();
    config.headers["Authorization"] = "Bearer " + sessionData.token;
    return config;
  }, function (error) {
    // Do something with request error
    return Promise.reject(error);
  });

// Add a response interceptor
axios.interceptors.response.use(function (response) {
    // Do something with response data
    if (response.headers && response.headers["access-token"]) {
        _setSessionData(response.headers["access-token"]);
    }
    return response;
  }, function (error) {
    // Do something with response error
    return Promise.reject(error);
  });

export const initializeSession = () => {
    return axios
        .post<any>(_baseUrl + enpoints.session, {
            //   cancelToken: this.cancelTokenSource.token,
            method: 'POST',
            headers: {
                "Content-Type": "application/json"
            },
            // timeout: 5000
        })
    // .then(response => {
    //     console.log("response", response);
    //     // Set authenticated
    //     const sessData:authResponse = decode(response.headers["access-token"]);
    //     if(sessData.sid !== ""){
    //        console.log("dispatch user authenticated"); 
    //     }
    //     console.log(sessData);
    // })
    // .catch(ex => {
    //     console.log("ex", ex);
    // });
}