import React from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { RouteComponentProps, withRouter } from "react-router-dom";

import { oauthlogin } from "../../redux/users/user.actions";
import { AuthApi } from "../../redux/users/user.api";
import { SocialUser } from "../../redux/users/user.interfaces";
import FacebookLogin from 'react-facebook-login';



interface IProps extends RouteComponentProps {
    oauthlogin: typeof oauthlogin;
}

class FacebookLoginButton extends React.Component<IProps, {}> {
    public constructor(props: IProps) {
        super(props);
        this.responseFacebook = this.responseFacebook.bind(this);
    }

    responseFacebook(response:any){
        console.log(response);
        const facebookUser: SocialUser = {
            accessToken: response.accessToken,
            source:'fb',
            email:response.email
        };
        AuthApi.oauthlogin(facebookUser)
            .then(response => {
                console.log("response", response);
                if(response.data.email){
                    facebookUser.email = response.data.email;
                    this.props.oauthlogin(facebookUser);
                } else {
                    console.log("we got problems");
                }
            })
            .catch(ex => {
                console.log("ex", ex);
            });
    }
    
    render() {
        return (
            <div>
                <FacebookLogin
                    appId="356395878162080" //APP ID NOT CREATED YET
                    fields="name,email,picture"
                    callback={this.responseFacebook}
                />
            </div>
        );
    }

}

const mapDispatchToProps = (dispatch: Dispatch) => {
	return {
		oauthlogin: bindActionCreators(oauthlogin, dispatch)
	};
}

export default connect(null, mapDispatchToProps)(withRouter(FacebookLoginButton));
