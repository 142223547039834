const dev = {
    // baseUrl : 'http://localhost:8443/',
    baseUrl : 'https://yy856vo22e.execute-api.us-west-2.amazonaws.com/authorization/',
    googleAuthClientID:"22493213785-5obqsgtnnbfqnr2k86t4e9kbkluhta4k.apps.googleusercontent.com",
  };
  
//   baseUrl : 'http://sc1dpkube01.eth.rsshpc1.sc1.science.roche.com/',
  const prod = {
    // baseUrl : 'http://sunusimgbld09.sun.roche.com/',
    baseUrl : 'https://yy856vo22e.execute-api.us-west-2.amazonaws.com/authorization/',
    googleAuthClientID:"22493213785-5obqsgtnnbfqnr2k86t4e9kbkluhta4k.apps.googleusercontent.com",
  };
  console.log("process.env.REACT_APP_ENV");
  console.log(process);
  console.log(process.env);
  console.log(process.env.REACT_APP_ENV);
  const config = process.env.REACT_APP_ENV === 'prod' ? prod : dev;

  export default config;