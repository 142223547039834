import * as React from "react";
import { RouteComponentProps, withRouter} from "react-router-dom";
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

import Login from './Login';
import { UserState } from "../redux/users/user.interfaces";


interface IProps extends RouteComponentProps {
  user: UserState;
}
interface IState {
  user: UserState;
}

// const Header: React.SFC<IProps> = (props) => {
class Header extends React.Component<IProps, IState> {
  public constructor(props: IProps) {
    super(props);
    // console.log("props",props);
    this.state = {
      user: this.props.user
    };
  }
  public componentWillReceiveProps(nextProps:any) {
      if(nextProps.user !== this.state.user){
          this.setState({ user:  nextProps.user});
      }
  }

  public render(){
    return (
      <div>
        <AppBar position="static" color="default">
          <Toolbar>
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="center"
          >
            <Typography variant="h6" color="inherit">
              AFellow Print
            </Typography>
            {/* <NavLink to="/catalog">catalog</NavLink>
            <NavLink to="/design-studio">design-studio</NavLink> */}
            <Login/>
          </Grid>
          </Toolbar>
        </AppBar>
      </div>
    );
  }
};
  
export default withRouter(Header);
  